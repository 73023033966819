import React, {useEffect, useState} from "react"
import ReactECharts from 'echarts-for-react';
import Select from 'react-select';
import './App.css'

const App = () => {

    useEffect(() => {
        fetchDevices()
    }, [])

    const [deviceIds, setDeviceIds] = useState([])
    const fetchDevices = () => {
        fetch("https://ofpzt7gpqb.execute-api.us-east-1.amazonaws.com/prod/devices")
            .then(response => {
                return response.json()
            }, err => {
                console.log(`Error fetching from API: ${err}`)
                return {Items: []}
            })
            .then(data => {
                let deviceIds = data.Items.map((label) => ({label, value: label.toLowerCase()}));
                setDeviceIds(deviceIds)
            })
    }

    const fetchDeviceData = (deviceId) => {
        deviceId = deviceId.split("/")[1]
        console.log(`Fetching data for device: ${deviceId}`)
        fetch(`https://ofpzt7gpqb.execute-api.us-east-1.amazonaws.com/prod/device/${deviceId}`)
            .then(response => {
                return response.json()
            }, err => {
                console.log(`Error fetching from API: ${err}`)
                return {Items: []}
            })
            .then(data => {
                console.log(`setting device data`)
                Object.keys(data.Predictions).forEach((key => {
                    let thisPrediction = data.Predictions[key]
                    console.log(thisPrediction)
                    predictions.push(
                        [
                            {
                                name: thisPrediction['predicted_activity'],
                                xAxis: new Date(Number(thisPrediction['start_time'])),
                                itemStyle: {
                                    color: 'rgba(255, 173, 177, 0.4)'
                                },
                            },
                            {
                                xAxis: new Date(Number(thisPrediction['end_time'])),
                            }
                        ]
                    )
                }))
                Object.keys(data.Items).forEach(key => {
                    hr.push([
                        new Date(Number(data.Items[key].timestamp)),
                        Number(data.Items[key].payload.n_heart_rate)])
                    spo2.push([
                        new Date(Number(data.Items[key].timestamp)),
                        Math.round((Number(data.Items[key].payload.n_spo2) + Number.EPSILON) * 100) / 100])
                    temp.push([
                        new Date(Number(data.Items[key].timestamp)),
                        Math.round((Number(data.Items[key].payload.temperature) + Number.EPSILON) * 100) / 100])
                    predicted_activity.push([
                        new Date(Number(data.Items[key].timestamp)),
                        data.Items[key].predicted_activity ? data.Items[key].predicted_activity : "unknown"])
                })
                setOptions({
                    grid: {top: 50, right: 50, bottom: 20, left: 50},
                    xAxis: {
                        type: 'time',
                        axisLabel: {
                            formatter: {
                                year: '{yyyy}-{MM}-{dd} {HH}:{mm}',
                                month: '{yyyy}-{MM}-{dd} {HH}:{mm}',
                                day: '{yyyy}-{MM}-{dd}',
                                hour: '{yyyy}-{MM}-{dd} {HH}:{mm}',
                                minute: '{yyyy}-{MM}-{dd} {HH}:{mm}',
                                second: '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}',
                                none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss}'
                            }
                        }
                    },
                    yAxis: {},
                    series: [
                        {
                            name: "SpO2",
                            data: spo2,
                            type: "scatter",
                            smooth: true
                        },
                        {
                            name: "HR",
                            data: hr,
                            type: "scatter",
                            smooth: true,
                        },
                        {
                            name: "Temperature (C)",
                            data: temp,
                            type: "scatter",
                            smooth: true,
                        },
                        {
                            name: "Predicted Activity",
                            data: predicted_activity,
                            type: "scatter",
                            markArea: {
                                data: predictions
                            },
                        },
                    ],
                    tooltip: {
                        trigger: "axis",
                    },
                    legend: {
                        show: true,
                    },
                    toolbox: {
                        right: 10,
                        feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            restore: {},
                            saveAsImage: {}
                        }
                    },
                    dataZoom: [
                        {
                            start: 90,
                            end: 100,
                        },

                        {
                            type: 'inside',
                        },
                    ],
                })
            })
    }

    const hr = []
    const spo2 = []
    const temp = []
    const predicted_activity = []
    const predictions = []


    const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState({});
    const handleChange = (selectedOption) => {
        setSelected(selectedOption);
        console.log(`Option selected:`, selectedOption);
        fetchDeviceData(selectedOption.label)
    };

    return (<div>
        <div id="select">Choose a device ID to see data for:
            <Select options={deviceIds}
                    onChange={handleChange}
                    getOptionValue={option => option.label}/>
        </div>
        <div id="chart">

            {selected &&
                (<ReactECharts
                    option={options}
                    style={{height: '90vh', width: '100%'}}
                />)
            }
        </div>
    </div>);
}
export default App;